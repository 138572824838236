<template>
  <!-- 一个科目一个内容项 -->
  <div class="scroll_card">
    <div class="head">
      <div class="title">{{title}}</div>
      <div class="more">
        <img src="@/static/icon-更多@2x.png"
             @click="toPage"
             alt="">
      </div>
    </div>

    <div class="list_warp">
      <div id="items"
           ref="itemRefs">
        <!-- 复习讲义模块 -->
        <div v-if="showCard"
             class="center">
          <div class="item"
               v-for="(item,index) in list"
               :key="index">
            <detialCard :content="item"
                        @handleEmit='toInfo(item)'
                        :options="subjectCardOptions" />
          </div>
        </div>
        <!-- 公益视频模块 -->
        <div v-else
             class="center">
          <div :class="`video ${getclass(item)}`"
               v-show="!showCard"
               v-for="(item,index) in list"
               :key="index + 'index'">
            <div class="subject">
              {{item.subject_name}}
            </div>
            <div class="desc">
              {{item.filename}}
            </div>
            <div class="btn"
                 @click="toInfo(item)"
                 :style="{'background':backgColor1[item.subject_id].videoColor}">
              {{btn_text}}<img src="@/static/goodVideo/icon-播放@2x.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, toRef, defineProps, reactive } from "@vue/runtime-core"
import { useRouter } from 'vue-router'
import { backgoundColor } from './background.js'
import detialCard from './detailCard.vue'
let subjectCardOptions = reactive({
  operationTitle: '讲义详情',
  colorList: backgoundColor
})
const backgColor1 = backgoundColor
const itemRefs = ref(null)
const timeInterValId = ref(0)
let router = useRouter()
const { title, list, url, btn_text, showCard } = defineProps({
  title: {
    type: String,
    default: ''
  },
  list: {
    type: Array,
    default: []
  },
  url: {
    type: String,
    default: ''
  },
  btn_text: {
    type: String,
    default: ''
  },
  showCard: {
    type: Boolean,
    default: true
  }
})
onMounted(() => {
  //let oitems = document.getElementById('items')
  // console.log("list", list)
  // oitems.addEventListener('touchstart', () => {
  //   clearVal()
  // })
})
const toPage = () => {
  router.push(url)
}
const emits = defineEmits(['toInfo'])
const toInfo = (row) => {
  // 在散户的优质课程里，重复用了该组件   所以可能要拿id做一下判断
  emits('toInfo', row)
}

const clearVal = () => {
  //clearInterval(timeInterValId.value)
}
const getclass = (item) => {
  return `s${item.subject_id}`
}
</script>

<style lang="scss" scoped>
.scroll_card {
  position: relative;
  width: 1468rem;
  max-width: calc(100% - 242rem);
  margin-left: 44rem;
}
.head {
  display: flex;
  justify-content: space-between;
  margin-top: 30rem;
  margin-bottom: 10rem;
  .title {
    font-weight: 800;
    font-size: 36rem;
    color: #ffffff;
  }
  .more {
    cursor: pointer;
    img {
      width: 150rem;
      height: 54rem;
    }
  }
}
.list_warp {
  // width: 1468rem;
  width: 100%;
  display: flex;
  overflow: hidden;
  .left,
  .right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 240rem;
    img {
      width: 30rem;
      height: 68rem;
    }
  }
  .left {
    left: -45rem;
  }

  .right {
    right: -45rem;
  }
  #items {
    overflow: scroll;
    ::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .center {
    flex: 1;
    display: flex;
    overflow-x: scroll;
    margin-top: 10px;
    -webkit-transition: all 1s;
    transition: all 1s;
    padding-left: 5px;
    .item {
      flex-shrink: 0;
      position: relative;
      width: 300rem;
      height: 310rem;
      background-color: white;
      // margin-right: 72rem;
      margin-right: 5%;
      border-radius: 10rem;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
      &:last-child {
        margin-right: 0;
      }
      .text {
        width: 290rem;
        height: 210rem;
        margin: 5rem;
        border-radius: 20rem;
        position: relative;
        background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        .card {
          height: 60rem;
          padding: 8rem 36rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url("../../../static/card.png");
          background-size: 100% 110%;
          font-size: 24rem;
          font-weight: bold;
          color: #ffffff;
          background-repeat: no-repeat;
          position: absolute;
          left: -11px;
          top: 7px;
        }
      }
      .desc {
        display: inline-block;
        text-align: center;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 90rem;
        margin-top: 40rem;
        font-size: 24rem;
      }
      .opertion {
        .score {
          display: flex;
          div {
            padding-right: 3px;
            display: flex;
            align-items: center;
            margin-left: 2px;
            color: #999999;
            font-weight: 500;
            font-size: 22rem;
            img {
              width: 21px;
              height: 20px;
              margin-left: 6rem;
            }
          }
        }
        .btn_warp {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20rem;
          .btn {
            padding: 7rem 24rem;
            font-size: 24rem;
            font-weight: bold;
            height: 54rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 40rem;
            border: 2rem solid #cccccc;
            cursor: pointer;
          }
        }
      }
    }
    .video {
      width: 340rem;
      height: 270rem;
      flex-shrink: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: 28rem;
      .subject {
        font-size: 24rem;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        height: 43rem;
        line-height: 33rem;
        align-items: center;
      }
      .desc {
        width: calc(100% - 24rem);
        margin: 0 auto;
        margin-top: 24rem;
        margin-bottom: 15rem;
        height: 104rem;
        padding: 0 15rem;
        line-height: 33rem;
        text-align: center;
        font-weight: bold;
        font-size: 24rem;
        color: #000000;
        -webkit-line-clamp: 3;
        text-overflow: -o-ellipsis-lastline;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .btn {
        width: 200rem;
        height: 54rem;
        border-radius: 18px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: bold;
        font-size: 22rem;
        color: #ffffff;
        img {
          width: 30rem;
          margin-left: 10rem;
        }
      }
    }
  }
}
.s1 {
  background-image: url("../../../static/goodVideo/bg-语文@2x.png");
}
.s7 {
  background-image: url("../../../static/goodVideo/bg-道德与法治@2x.png");
}
.s10 {
  background-image: url("../../../static/goodVideo/bg-化学@2x.png");
}
.s5 {
  background-image: url("../../../static/goodVideo/bg-化学@2x.png");
}
.s6 {
  background-image: url("../../../static/goodVideo/bg-历史@2x.png");
}
.s9 {
  background-image: url("../../../static/goodVideo/bg-生物@2x.png");
}
.s2 {
  background-image: url("../../../static/goodVideo/bg-数学@2x.png");
}
.s4 {
  background-image: url("../../../static/goodVideo/bg-物理@2x.png");
}
.s3 {
  background-image: url("../../../static/goodVideo/bg-英语@2x.png");
}
</style>