<template>
  <div class="course-container">
    <div class="card">
      <SubjectCard ref="CardRef"
                   title="复习讲义"
                   btn_text="讲义详情"
                   @toInfo="toInfo"
                   url="/course/reviewHandout"
                   :list="handoutList" />
      <div class="btm_img"></div>
      <SubjectCard ref="CardRef"
                   style="margin-top:25px"
                   title="视频讲解"
                   :showCard="false"
                   @toInfo="toPreview"
                   url="/lecture"
                   btn_text="开始播放"
                   :list="goodVideo" />
    </div>
  </div>

</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { instance } from '@/utils/http.js'
import SubjectCard from './components/subjectCard.vue'

const router = useRouter()

let handoutList = ref([])
let goodVideo = ref([])
const getGoodsVideo = () => {
  instance({
    url: '/api/v1/video/recommended',
    method: 'get'
  }).then(res => {
    goodVideo.value = res.data.list
  })
}
const toPreview = (row) => {
  router.push('/course/preview?type=video&url=' + row.url)
}
const toInfo = (row) => {
  if (row.colorId) {
    handleSubjectCardClick(row.lecture_id)
  }
}
const handleSubjectCardClick = async (lecture_id) => {
  const { data: handoutInfo } = await instance({
    url: '/api/v1/lecture/get_lecture',
    method: 'get',
    params: { lecture_id }
  })
  const url = handoutInfo.file_url
  // 打开新页面并展示pdf
  // window.injectedObject.showPdf(url);
  window.open(url);
  //router.push('/course/preview?type=video&url=' + url)

}
const getLectureMaterials = async () => {
  const { data: handoutData } = await instance({
    url: '/api/v1/lecture/random',
    method: 'get'
  })

  handoutList.value = handoutData.map(item => {
    item.title = item.lecture_name;
    item.subject = item.subject_name;
    item.id = item.lecture_id;
    item.colorId = item.subject_id;
    return item
  })
}
onMounted(() => {
  getLectureMaterials()
  getGoodsVideo()
})
</script>

<style lang="scss" scoped>
.course-container {
  margin-top: 10rem;
  margin-left: 6%;
  width: 100%;
  overflow: hidden;
  .card {
    margin: 0 auto;
    margin-top: 23px;
    .btm_img {
      width: 1606rem;
      height: 100rem;
      margin-top: -20rem;
      background-image: url("../../static/course/bg-short-shelf.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
